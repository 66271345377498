.about_container {
    display: grid;
    grid-template-columns: 25% 65%;
    gap: 10rem;
}

.about_img {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
}

.about_img > img {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about_img > img:hover {
    transform: rotate(0);
}

.about_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.about_card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
}

.about_card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about_icon {
    color: var(--color-primary);
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.about_card > small {
    color: var(--color-light);
}

.about_text > p {
    color: var(--color-light);
    margin: 2rem 0 1rem;
    font-size: 1.1rem;
    font-weight: 300;
}

@media screen and (max-width: 1024px) {
    .about_container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about_img {
        width: 50%;
        margin: 2rem auto 4rem;
    }
    .about_container p {
        margin: 1rem 0 1rem;
    }
  }
  
@media screen and (max-width: 600px) {
    .about_cards {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .about_img {
        width: 50%;
        margin: 0 auto 2rem;
    }
    .about_container {
        text-align: center;
        width: 80%;
        gap: 10%;
    }
    .about_container p {
        margin: 1rem 0 1rem;
    }
}