footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    margin-top: 5rem;
}

footer a {
    color: var(--color-bg);
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer_socials {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

.footer_socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer_copyright {
    margin-bottom: 6rem;
    color: var(--color-bg);
}

@media screen and (max-width: 1024px) {
    
  }
  
@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1rem;
    }
}