@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&family=Kaushan+Script&display=swap');


* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg:#1f1f38;
  --color-bg-variant:#2c2c6c;
  --color-primary:#4db5ff;
  --color-primary-variant:rgba(77,181,255,0.4);
  --color-white:#fff;
  --color-light:rgba(255,255,255,0.6);

  --transision: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg);
  color: var(--color-white);
}

.container {
  width:var(--container-width-lg);
  margin: 0 auto;
}

section {
  margin-top: 8rem;
}

.section_title {
  font-size: 2.5rem;
  font-weight: 500;
}
.section_title::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transision);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transision);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .container{
    width: var(--container-width-md);
  }
  
  section {
    margin-top:6rem;
  }
}

@media screen and (max-width: 600px) {
  .container{
    width: var(--container-width-sm);
  }
  
  section {
    margin-top:2rem;
  }
}