.portfolio_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.portfolio_item {
    background:var(--color-bg-variant);
    padding: 0.3rem;
    grid-column: span 1;
    border-radius: 1rem;
    border: 1px solid transparent;
    transition: var(--transition);
    justify-self: center; /* Center horizontally within the cell */
    align-self: center; /* Center vertically within the cell */
}

.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item_image {
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
}

.portfolio_item_title {
    margin: 1rem 0 1rem;
}

.portfolio_item a {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}


@media screen and (max-width: 1500px) {
    .portfolio_container {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .portfolio_container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
}
  
@media screen and (max-width: 600px) {
    .portfolio_container {
        margin: 0 2rem ;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}