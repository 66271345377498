.container_testimonials {
    width: 40%;
}

.client_avatar {
    width: 5rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.5rem solid var(--color-primary-variant);
}

.testimonial {
    background: var(--color-primary-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client_review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    padding: 0px 30px;
}

@media screen and (max-width: 1024px) {
    .container_testimonials {
        width: 60%;
    }
}

@media screen and (max-width: 600px) {
    .container_testimonials {
        width: 80%;
    }
}
