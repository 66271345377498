.container_skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skill_item_image {
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
}

.skill_item_title {
    margin: 1rem 0 1rem;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 100;
}

.skill_item {
    flex: 0 0 calc(13%);
    padding: 20px;
    margin: 10px;
}

@media screen and (max-width: 1300px) {
    .skill_item {
        flex: 0 0 calc(16.66%);
    }
}

@media screen and (max-width: 1024px) {
    .skill_item {
        flex: 0 0 calc(18%);
    }
}

@media screen and (max-width: 800px) {
    .skill_item {
        flex: 0 0 calc(23%);
    }
}
  
@media screen and (max-width: 600px) {
    .skill_item {
        flex: 0 0 calc(20.66%);
    }
}