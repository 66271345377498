header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header_container .header_title {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

.header_container h4 {
    font-weight: 300;
    font-size: 1.2rem;
}
.header_container span{
    font-family: "Kaushan Script", cursive;
    font-size: 4rem;
    font-weight: 500;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
}
.header_container span:after {
    position: absolute;
    top: 40px;
    right: 0;
    width: 30px;
    height: 4px;
    content: "";
    background: #fff;
    margin-right: -30px;
}
.header_container span:before {
    position: absolute;
    top: 40px;
    left: 0;
    width: 30px;
    height: 4px;
    content: "";
    background: #fff;
    margin-left: -30px;
}

.header_btns {
    margin-top: 3rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.header_social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_social::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
.header_social::before {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.header_model {
    width: 20%;
    height: 60%;
    position: absolute;
    left: calc(50% - 10%);
    bottom: 0;
    margin-top: 4rem;
}

.header_scroll {
    position: absolute;
    right: -0rem;
    bottom: 3rem;
}

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }
  }
  
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header_social, .header_scroll {
        display: none;
    }
}